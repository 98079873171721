import { BookSize, TILE_SIZES } from '@mixtiles/web-backend-shared'

export function getSizesData(): Record<
  string,
  {
    displayName: string
    longDisplayName: string
    longDisplayNameCms: string
    trackingName: TILE_SIZES | BookSize
  }
> {
  return {
    [TILE_SIZES.SQUARE_4X4]: {
      displayName: " 4''",
      longDisplayName: "4''x4''",
      longDisplayNameCms: '10x10 cm',
      trackingName: TILE_SIZES.SQUARE_4X4,
    },
    [TILE_SIZES.RECTANGLE_4X6]: {
      displayName: "4''x6''",
      longDisplayName: "4''x6''",
      longDisplayNameCms: '10x15 cm',
      trackingName: TILE_SIZES.RECTANGLE_4X6,
    },
    [TILE_SIZES.RECTANGLE_6X4]: {
      displayName: "6''x4''",
      longDisplayName: "6''x4''",
      longDisplayNameCms: '15x10 cm',
      trackingName: TILE_SIZES.RECTANGLE_6X4,
    },
    [TILE_SIZES.SQUARE_8X8]: {
      displayName: " 8''",
      longDisplayName: "8''x8''",
      longDisplayNameCms: '21x21 cm',
      trackingName: TILE_SIZES.SQUARE_8X8,
    },
    [TILE_SIZES.RECTANGLE_6X8]: {
      displayName: "6''x8''",
      longDisplayName: "6''x8''",
      longDisplayNameCms: '20x15 cm',
      trackingName: TILE_SIZES.RECTANGLE_6X8,
    },
    [TILE_SIZES.RECTANGLE_8X6]: {
      displayName: "8''x6''",
      longDisplayName: "8''x6''",
      longDisplayNameCms: '15x20 cm',
      trackingName: TILE_SIZES.RECTANGLE_8X6,
    },
    [TILE_SIZES.RECTANGLE_11X8]: {
      displayName: "11''x8''",
      longDisplayName: "11''x8''",
      longDisplayNameCms: '28x21 cm',
      trackingName: TILE_SIZES.RECTANGLE_11X8,
    },
    [TILE_SIZES.RECTANGLE_8X11]: {
      displayName: "8''x11''",
      longDisplayName: "8''x11''",
      longDisplayNameCms: '21x28 cm',
      trackingName: TILE_SIZES.RECTANGLE_8X11,
    },
    [TILE_SIZES.HONEYCOMB_10X11]: {
      displayName: "10''x11''",
      longDisplayName: "10''x11''",
      longDisplayNameCms: '25x29 cm',
      trackingName: TILE_SIZES.HONEYCOMB_10X11,
    },
    [TILE_SIZES.HONEYCOMB_11X10]: {
      displayName: "11''x10''",
      longDisplayName: "11''x10''",
      longDisplayNameCms: '29x25 cm',
      trackingName: TILE_SIZES.HONEYCOMB_11X10,
    },
    [TILE_SIZES.SQUARE_12X12]: {
      displayName: " 12''",
      longDisplayName: "12''x12''",
      longDisplayNameCms: '32x32 cm',
      trackingName: TILE_SIZES.SQUARE_12X12,
    },
    [TILE_SIZES.SQUARE_20X20]: {
      displayName: " 20''",
      longDisplayName: "20''x20''",
      longDisplayNameCms: '50x50 cm',
      trackingName: TILE_SIZES.SQUARE_20X20,
    },
    [TILE_SIZES.SQUARE_30X30]: {
      displayName: " 30''",
      longDisplayName: "30''x30''",
      longDisplayNameCms: '76x76 cm',
      trackingName: TILE_SIZES.SQUARE_30X30,
    },
    [TILE_SIZES.SQUARE_40X40]: {
      displayName: " 40''",
      longDisplayName: "40''x40''",
      longDisplayNameCms: '101x101 cm',
      trackingName: TILE_SIZES.SQUARE_40X40,
    },
    [TILE_SIZES.RECTANGLE_16X12]: {
      displayName: "16''x12''",
      longDisplayName: "16''x12''",
      longDisplayNameCms: '42x32 cm',
      trackingName: TILE_SIZES.RECTANGLE_16X12,
    },
    [TILE_SIZES.RECTANGLE_12X16]: {
      displayName: "12''x16''",
      longDisplayName: "12''x16''",
      longDisplayNameCms: '32x42 cm',
      trackingName: TILE_SIZES.RECTANGLE_12X16,
    },
    [TILE_SIZES.RECTANGLE_16X20]: {
      displayName: "16''x20''",
      longDisplayName: "16''x20''",
      longDisplayNameCms: '50x40 cm',
      trackingName: TILE_SIZES.RECTANGLE_16X20,
    },
    [TILE_SIZES.RECTANGLE_20X16]: {
      displayName: "20''x16''",
      longDisplayName: "20''x16''",
      longDisplayNameCms: '40x50 cm',
      trackingName: TILE_SIZES.RECTANGLE_12X16,
    },
    [TILE_SIZES.RECTANGLE_20X27]: {
      displayName: "20''x27''",
      longDisplayName: "20''x27''",
      longDisplayNameCms: '50x69 cm',
      trackingName: TILE_SIZES.RECTANGLE_20X27,
    },
    [TILE_SIZES.RECTANGLE_27X20]: {
      displayName: "27''x20''",
      longDisplayName: "27''x20''",
      longDisplayNameCms: '69x50 cm',
      trackingName: TILE_SIZES.RECTANGLE_27X20,
    },
    [TILE_SIZES.RECTANGLE_27X36]: {
      displayName: "27''x36''",
      longDisplayName: "27''x36''",
      longDisplayNameCms: '69x91 cm',
      trackingName: TILE_SIZES.RECTANGLE_27X36,
    },
    [TILE_SIZES.RECTANGLE_36X27]: {
      displayName: "36''x27''",
      longDisplayName: "36''x27''",
      longDisplayNameCms: '91x69 cm',
      trackingName: TILE_SIZES.RECTANGLE_36X27,
    },
    [TILE_SIZES.RECTANGLE_25X8]: {
      displayName: "25''x8''",
      longDisplayName: "25''x8''",
      longDisplayNameCms: '64x21 cm',
      trackingName: TILE_SIZES.RECTANGLE_25X8,
    },
    [TILE_SIZES.RECTANGLE_22X44]: {
      displayName: "22''x44''",
      longDisplayName: "22''x44''",
      longDisplayNameCms: '56x112 cm',
      trackingName: TILE_SIZES.RECTANGLE_22X44,
    },
    [TILE_SIZES.RECTANGLE_44X22]: {
      displayName: "44''x22''",
      longDisplayName: "44''x22''",
      longDisplayNameCms: '112x56 cm',
      trackingName: TILE_SIZES.RECTANGLE_44X22,
    },
    [BookSize.BOOK_8X8]: {
      displayName: " 8''",
      longDisplayName: "8''x8''",
      longDisplayNameCms: '21x21 cm',
      trackingName: BookSize.BOOK_8X8,
    },
    [BookSize.BOOK_10X10]: {
      displayName: " 10''",
      longDisplayName: "10''x10''",
      longDisplayNameCms: '25x25 cm',
      trackingName: BookSize.BOOK_10X10,
    },
    [BookSize.BOOK_12X12]: {
      displayName: " 12''",
      longDisplayName: "12''x12''",
      longDisplayNameCms: '32x32 cm',
      trackingName: BookSize.BOOK_12X12,
    },
    [TILE_SIZES.SPLIT_24X24]: {
      displayName: " 24''",
      longDisplayName: "24''x24''",
      longDisplayNameCms: '61x61 cm',
      trackingName: TILE_SIZES.SPLIT_24X24,
    },
    [TILE_SIZES.SPLIT_24X32]: {
      displayName: " 24''",
      longDisplayName: "24''x32''",
      longDisplayNameCms: '61x81 cm',
      trackingName: TILE_SIZES.SPLIT_24X32,
    },
    [TILE_SIZES.SPLIT_27X40]: {
      displayName: " 27''",
      longDisplayName: "27''x40''",
      longDisplayNameCms: '69x101 cm',
      trackingName: TILE_SIZES.SPLIT_27X40,
    },
    [TILE_SIZES.SPLIT_32X24]: {
      displayName: " 32''",
      longDisplayName: "32''x24''",
      longDisplayNameCms: '81x61 cm',
      trackingName: TILE_SIZES.SPLIT_32X24,
    },
    [TILE_SIZES.SPLIT_40X27]: {
      displayName: " 40''",
      longDisplayName: "40''x27''",
      longDisplayNameCms: '101x69 cm',
      trackingName: TILE_SIZES.SPLIT_40X27,
    },
    [TILE_SIZES.CIRCLE_10]: {
      displayName: " 24''",
      longDisplayName: "24''x32''",
      longDisplayNameCms: '61x81 cm',
      trackingName: TILE_SIZES.CIRCLE_10,
    },
  }
}
