import { styled, css } from 'styled-components'

export const _SIZES = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
} as const

export const _VARIANTS = {
  primary: 'primary',
  light: 'light',
  gray: 'gray',
  text: 'text',
} as const

export interface ButtonStylingProps {
  $size: string
  $variant: string
  $minWidth?: number
  $fullWidth?: boolean
  $color?: string
  $background?: string
  $noShrink?: boolean
}

function sizesStyling({ $size }: ButtonStylingProps) {
  switch ($size) {
    case _SIZES.sm:
      return css`
        min-width: 165px;
        height: 36px;
        font-size: 16px;
        border-radius: 6px;
      `
    case _SIZES.md:
      return css`
        min-width: 230px;
        height: 40px;
        font-size: 16px;
        border-radius: 6px;
      `
    case _SIZES.lg:
      return css`
        min-width: 209px;
        height: 52px;
        font-size: 18px;
        border-radius: 10px;
      `
    case _SIZES.xl:
      return css`
        min-width: 312px;
        height: 60px;
        font-size: 20px;
        border-radius: 10px;
      `
  }
}

function variantStyling({ $variant }: ButtonStylingProps) {
  switch ($variant) {
    case _VARIANTS.primary:
      return css`
        font-weight: 700;
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.primary};
        border: 0;
        &:hover&:not(:disabled) {
          background: ${({ theme }) => theme.colors.primaryAccent};
        }
      `
    case _VARIANTS.light:
      return css`
        font-weight: 700; // TODO: Ofek - need to check if it works and why it wasn't existing before
        color: ${({ theme }) => theme.colors.primary};
        background: ${({ theme }) => theme.colors.grayBright};
        border: 1px solid ${({ theme }) => theme.colors.grayMedium};

        &:hover&:not(:disabled) {
          background: ${({ theme }) => theme.colors.grayAccent};
          border-color: ${({ theme }) => theme.colors.grayAccent};
          color: ${({ theme }) => theme.colors.primaryAccent};
        }
      `
    case _VARIANTS.gray:
      return css`
        color: ${({ theme }) => theme.colors.black};
        background: ${({ theme }) => theme.colors.grayMedium};
        border: 1px solid rgba(181, 167, 172, 0.07);

        &:hover&:not(:disabled) {
          background: ${({ theme }) => theme.colors.grayAccent};
          border-color: ${({ theme }) => theme.colors.grayAccent};
          color: ${({ theme }) => theme.colors.black};
        }
      `
    case _VARIANTS.text:
      return css`
        color: ${({ theme }) => theme.colors.secondaryDark};
        opacity: 0.8;
        background: transparent;
        border: 0;
        text-decoration: underline;
        text-decoration-thickness: 1px;

        font-weight: 500;
        font-size: 15px;
        line-height: 36px;
        width: fit-content;
        white-space: nowrap;

        &:hover:not(:disabled) {
          opacity: 1;
          color: ${({ theme }) => theme.colors.deepBlackEmergencyOnly};
        }
      `
  }
}

export const ButtonStyling = css<ButtonStylingProps>`
  gap: 8px;
  flex-shrink: ${({ $noShrink }) => ($noShrink ? 0 : 1)};

  // Layout
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $minWidth }) =>
    $minWidth &&
    css`
      min-width: ${$minWidth}px;
    `};
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'fit-content')};

  // Text
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `};

  // Appearance
  ${({ $background }) =>
    $background &&
    css`
      background: ${$background};
      border-color: ${$background};
      &:hover&:not(:disabled) {
        background: ${$background};
      }
    `};
  cursor: pointer;
  user-select: none;
  outline: 0;
  transition:
    transform 0.1s,
    all 0.2s;

  &:disabled {
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
  }

  &:active {
    transform: scale(0.97);
  }

  ${sizesStyling};
  ${(props) => variantStyling(props)};

  & svg {
    height: 1em !important;
    width: 1em !important;
  }
`

export const ButtonContainer = styled.button<ButtonStylingProps>`
  ${ButtonStyling};
`

export const LinkContainer = styled.a<ButtonStylingProps>`
  ${ButtonStyling};
  text-decoration: none;
`

export const Text = styled.span``
