import { useEffect, useState, type PropsWithChildren } from 'react'

export default function NoSSR({ children }: PropsWithChildren) {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) {
    return null
  }

  return children
}

// Like NoSSR but returns a boolean, useful to avoid hydration errors
// (isClient() will cause a hydration error if used for rendering)
export function useNoSSR() {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  return isClient
}
