import { getUrlParam } from '../utils/UrlUtils'
import { artOnboardingManager } from '../Art/services/ArtOnboardingManager'
import { getTiles } from '../pages/PhotoStyler/tilesSaver'
import { isForcePricingGoogle } from './google'
import { getUserEmail } from './userEmail'
import UserManager from './UserManager'
import { isClient } from 'utils/runtimeUtils'
import { experimentManager } from './ExperimentManager/ExperimentManager'
import { getUserSettings, updateUserSettings } from './UserSettingsProvider'

const RECAPTURE_ONBOARDING_DAYS = 180

class EmailCaptureManager {
  constructor() {
    if (isClient() && this.shouldRecaptureEmail()) {
      artOnboardingManager.resetOnboardingStatus()
    }
  }

  /* More logic from EmailCapturePage could be extracted to here. For now just extract what we need for current features */
  shouldCaptureEmail() {
    if (getUrlParam('utm_source') === 'email' || getUrlParam('sms') === '1') {
      updateUserSettings({ passedEmailCaptureScreen: true })
      return false
    }

    if (isForcePricingGoogle(new URLSearchParams(window.location.search))) {
      return false
    }

    const shouldCollectEmail = !getUserEmail() || this.shouldRecaptureEmail()
    return (
      getTiles().length === 0 &&
      shouldCollectEmail &&
      !getUserSettings().passedEmailCaptureScreen
    )
  }

  // TRUE if passed photos / art onboarding
  passedAnyOnboarding() {
    return (
      experimentManager.isEnabled('straight-to-photos') ||
      !artOnboardingManager.shouldStartOnboardingProcess() ||
      !this.shouldCaptureEmail()
    )
  }

  passedJumbleOnboarding() {
    return (
      !artOnboardingManager.shouldStartOnboardingProcess() ||
      !this.shouldCaptureEmail()
    )
  }

  shouldRecaptureEmail() {
    return (
      UserManager.getDaysSinceLastVisit() > RECAPTURE_ONBOARDING_DAYS &&
      !getUserSettings().didPerformLogin
    )
  }
}

export const emailCaptureManager = new EmailCaptureManager()
