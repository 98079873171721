import React, { type PropsWithChildren, useContext, useEffect } from 'react'
import SideMenu from './SideMenu.client'
import { analytics, EVENT_NAMES } from '../../services/Analytics/Analytics'
import { useLocation } from 'react-router-dom'
import { useNoSSR } from 'utils/NoSSR'

type SideMenuContextType = {
  open: () => void
}

const SideMenuContext = React.createContext<SideMenuContextType | undefined>(
  undefined
)

export function useSideMenu() {
  const context = useContext(SideMenuContext)
  if (!context) {
    throw new Error('useSideMenu must be used within a SideMenuProvider')
  }

  return context
}

export default function SideMenuProvider({ children }: PropsWithChildren) {
  const [isOpen, setIsOpen] = React.useState(false)
  const isClient = useNoSSR()
  const location = useLocation()

  useEffect(() => {
    setIsOpen(false)
  }, [location])

  const open = () => {
    setIsOpen(true)
    analytics.track(EVENT_NAMES.MENU_OPENED)
  }

  return (
    <SideMenuContext.Provider value={{ open }}>
      {isClient && (
        <SideMenu open={isOpen} onDismiss={() => setIsOpen(false)} />
      )}
      {children}
    </SideMenuContext.Provider>
  )
}
