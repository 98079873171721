import { styled, css } from 'styled-components'
import { alpha } from '@mui/material'

export const _VARIANTS = {
  simple: 'simple',
  white: 'white',
  withLabel: 'withLabel',
}

const PADDING = 24

interface ContainerProps {
  $fullWidth?: boolean
  $minWidth?: number
  $invalid?: boolean
  $variant: keyof typeof _VARIANTS
}

function hidePlaceholder({ $variant }: { $variant: keyof typeof _VARIANTS }) {
  switch ($variant) {
    case _VARIANTS.simple:
    case _VARIANTS.white:
      return css`
        display: none;
      `
    case _VARIANTS.withLabel:
      return css`
        font-size: 12px;
        top: 14px;
        left: ${PADDING}px;
      `
  }
}

function containerVariantStyling({ $variant, $invalid }: ContainerProps) {
  if ($invalid) {
    return css`
      background: ${({ theme }) => alpha(theme.colors.errorRed, 0.1)};
      border: 2px solid ${({ theme }) => theme.colors.errorRed};
    `
  }

  switch ($variant) {
    case _VARIANTS.simple:
      return css`
        background: ${({ theme }) => theme.colors.grayBright};
      `
    case _VARIANTS.white:
    case _VARIANTS.withLabel:
      return css`
        background: ${({ theme }) => theme.colors.white};
      `
  }
}

export const Container = styled.div<ContainerProps>`
  ${containerVariantStyling};

  // Layout
  display: flex;
  align-items: center;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'fit-content')};
  min-width: ${({ $minWidth }: ContainerProps) => $minWidth || '325px'};
  height: 60px;
  position: relative;
  box-sizing: border-box;

  // Appearance
  border: 2px solid ${({ theme }) => theme.colors.grayAccent};
  border-radius: 8px;
  overflow: hidden;
  cursor: text;
  transition: all 0.2s;

  &:focus-within {
    ${({ $invalid }) =>
      !$invalid &&
      css`
        border-color: ${({ theme }) => theme.colors.secondaryDark};
      `}
  }

  &:hover&:not(:disabled)&:not(:focus-within) {
    border-color: ${({ theme }) => alpha(theme.colors.grayMidDark, 0.4)};
  }

  // Hide placeholder if input is focused
  &:focus-within label {
    ${hidePlaceholder};
  }
`

interface PlaceholderProps {
  $variant: keyof typeof _VARIANTS
  $hasValue: boolean
}

function placeholderVariantStyling({ $variant }: PlaceholderProps) {
  switch ($variant) {
    case _VARIANTS.simple:
    case _VARIANTS.white:
      return css`
        font-size: 18px;
        font-weight: 500;
      `
    case _VARIANTS.withLabel:
      return css`
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
      `
  }
}

export const Placeholder = styled.label<PlaceholderProps>`
  ${({ $hasValue }: PlaceholderProps) =>
    $hasValue ? hidePlaceholder : undefined}

  ${placeholderVariantStyling};

  font-size: 16px;
  position: absolute;
  left: ${PADDING}px;
  right: ${PADDING}px;
  color: ${({ theme }) => theme.colors.grayDark};
  transition: all 0.2s;
  pointer-events: none;
`

export const PlaceholderChild = styled.label`
  font-size: 13px;
  font-weight: 400;
`

interface InputProps {
  $variant: string
}

function inputVariantStyling({ $variant }: InputProps) {
  switch ($variant) {
    case _VARIANTS.withLabel:
      return css`
        font-size: 16px;
        padding: 31px ${PADDING}px 13px;
      `
  }
}

export const Input = styled.input<InputProps>`
  // Layout
  width: 100%;

  // Text
  color: ${({ theme }) => theme.colors.black};
  font-size: 18px;
  font-weight: 500;

  // Appearance
  background: transparent;
  caret-color: ${({ theme }) => theme.colors.grayDark};
  padding: 21px ${PADDING}px;
  border: 0;
  outline: 0;

  ${inputVariantStyling};
`

export const InvalidMessage = styled.div`
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.errorRed};
  margin-top: 8px;
`
