/**
 * Returns whether a user arrived from Google Ads and it is supposed to have a
 * fixed pricing. Note: there is no need to use utm_source to identify Google as
 * the forcep parameter is only used by Google Shopping.
 *
 * This was added as a request from our google marketing team so we won't show
 * the onboarding quiz / discount banners (because it violates some Google's terms)
 */
export function isForcePricingGoogle(params: URLSearchParams) {
  return params.get('forcep') === '11'
}
